import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import BackgroundImage from "../assets/images/thanks/thanks.jpg"
import SEO from "../components/SEO"

const Thanks = () => (
  <section
    className="py-10 py-md-14 overlay overlay-black overlay-60 bg-cover"
    style={{ backgroundImage: `url(${BackgroundImage})` }}
  >
    <SEO title="Thank you" />
    <Container>
      <Row className="justify-content-center">
        <Col sm={12} md={10} lg={8} className="text-center">
          <h1 className="font-weight-bold text-white">Thank you for your interest in Lumis</h1>
          <p className="lead text-white-75 mb-0">
            You are currently on the waiting list, but we will reach out to you as soon we have new
            spots available for Sign Up.
          </p>
          <Button href="/" variant="primary" size="lg" className="mt-5">
            Bring me home
          </Button>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Thanks
